import React, { Component } from 'react';
import './App.css';

class Main extends Component {  
  render() {
      return (
        <div className="App">
          <header className="App-header">
              LooperHub
          </header>
        </div>
      );
  }
}

export default Main;