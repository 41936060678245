import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import Main from "./Main";
import AudioPlayer from "react-h5-audio-player";
import 'react-h5-audio-player/lib/styles.css';
import ReactMarkdown from 'react-markdown';
import { Pressable, Text, View, Modal } from 'react-native';
import { TouchableWithoutFeedback } from "react-native-web";

// TODO:
// URL as env var
// refactor into components
const Song = () => {
    const { user, title } = useParams();
    const [versions, setVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState('main');
    const [song, setSong] = useState('Info: Loading');
    const [songDescription, setSongDescription] = useState({description: '', loops: []});
    const [loopActivity, setLoopActivity] = useState({});
    const [currentListeningTime, setCurrentListeningTime] = useState(0);
    const sourceLink = `https://github.com/${user}/${title}/tree/${selectedVersion}`;
    const [currentLoopSummary, setCurrentLoopSummary] = useState({'id': '', 'short': '', 'long': ''});
    
    useEffect(() => {
        const fetchVersions = async () => {
            fetch(`https://looperhub.com/api/versions/${user}/${title}`)
            //fetch(`/versions/${user}/${title}`)
            .then((response) => response.json())
            .then(
                (data) => {
                    setVersions(data.map(version => ({value: version, label: version})));
                }
            )
        }
        fetchVersions();
    }, [title, user]);

    useEffect(() => {
        const fetchSong = async () => {
            fetch(`https://looperhub.com/api/song/${user}/${title}/${selectedVersion}`)
            //fetch(`/song/${user}/${title}/${selectedVersion}`)
                .then((response) => response.text())
                .then(
                    (data) => {
                        if(!data.startsWith('Error')) {
                            setSong(`https://looperhub.com/static/${data}.mp3`);
                            //setSong(`http://localhost:3000/${data}.mp3`);
                            fetch(`https://looperhub.com/static/${data}.json`)
                            //fetch(`http://localhost:3000/${data}.json`)
                                .then((response) => response.json())
                                .then((responseJson) => {
                                    setSongDescription(responseJson);
                            });
                        }
                        else {
                            // TODO learn error handling
                            setSong(data);
                        }
                    }
                )
        }
        fetchSong();
    }, [title, user, selectedVersion]);

    useEffect(() => {
        let loops = songDescription.loops;
        let loopActivity = {};
        for (let loop in loops) {
            let occurences = loops[loop]["occurences"]
            let loopColor = "transparent";
            for (let segment in occurences) {
                if (currentListeningTime > occurences[segment][0] &
                    currentListeningTime < occurences[segment][1]) {
                        loopColor = "green";
                        break;
                    }
            }
            loopActivity[loops[loop]["id"]] = loopColor;
        }
        setLoopActivity(loopActivity);
    }, [songDescription, currentListeningTime]);

    const handleVersionSelect = e => {
        setSelectedVersion(e.value)
        setSong('Info: loading');
    };

    const handleListening = e => {
        setCurrentListeningTime(e.target.currentTime * 1000);
    };

    const handleEnded = e => {
        setCurrentListeningTime(e.target.currentTime * 1000);
    };

    const handlePlayerError = e => {
        console.log("refetching song");
    };

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <div>
            <ReactMarkdown>
                {isReadMore ? text.split('\n').slice(0, 3).join('\n') : text}
            </ReactMarkdown>
            <span
                onClick={toggleReadMore}
                style={{ color: "blue" }}
            >
                {isReadMore ? "...read more" : " show less"}
            </span>
            </div>
        );
    };

    if(versions.length === 0) {
        return (
            <Main /> 
        );
    }
    else {
        return (
            <div className="App">
            <header className="App-header">

                <Text style={{fontSize: 30, color: "white"}}>
                    <Link to={`/${user}`}>{user}</Link>/<Link to={`/${user}/${title}`}>{title}</Link>
                </Text>

                {song.startsWith('Error') | song.startsWith('Info') ? (
                    <div>{song}</div>
                ) : (
                    <div style={{width: '100%'}}>
                    <AudioPlayer
                    src={song}
                    title={title}
                    onListen={handleListening}
                    onEnded={handleEnded}
                    onError={handlePlayerError}
                    />
                    </div>
                )}

                <View style={{flexDirection: "row", flexWrap: "wrap", alignSelf: 'center', justifyContent: 'space-around'}}>
                {songDescription.loops.map(loop => (
                    <Pressable
                        onPress={() => setCurrentLoopSummary(loop)}
                        style={{backgroundColor: loopActivity[loop["id"]],
                                paddingHorizontal: 10,
                                borderColor: 'green',
                                borderWidth: 1,
                                borderRadius: 10,
                                height: 30,
                            }}
                            >
                        <Text style={{fontSize: 22, textTransform: "uppercase", color: "white"}}>+ {loop["id"]}</Text>
                    </Pressable>
                ))}
                </View>

                <Modal
                    transparent={true}
                    visible={currentLoopSummary["id"] !== ''}
                    >
                        <TouchableWithoutFeedback onPress={() => setCurrentLoopSummary({'id': ''})}>
                        <View style={{
                            flex: 1,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#000000CC'
                        }}>
                            <Text style={{fontSize: 22, textTransform: "uppercase", backgroundColor: "white"}}>Loop: {currentLoopSummary["id"]}</Text>
                            <Text style={{fontSize: 22, backgroundColor: "white"}}>Instrument: {currentLoopSummary["short"]}</Text>
                            <Text style={{fontSize: 22, backgroundColor: "white"}}>{currentLoopSummary["long"]}</Text>
                            <Pressable style={{width: '100%'}}>
                                <AudioPlayer src={`https://github.com/${user}/${title}/raw/main/loops/${currentLoopSummary["id"]}.mp3`}/>
                            </Pressable>
                        </View>
                        </TouchableWithoutFeedback>
                </Modal>

                <br/>

                <ReadMore>
                    {songDescription.description}
                </ReadMore>

                <br/>

                <Text style={{fontSize: 35, color: "white"}}>Version:</Text>
                <Select
                options={versions}
                onChange={handleVersionSelect}
                defaultValue={{value: "main", label: "main"}}
                label="Song version"
                styles={{
                    control: (provided) => ({
                        ...provided,
                        width: "max-content"
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontWeight: state.isSelected ? "bold" : "normal",
                      color: "black"
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black"
                    }),
                    menu: (provided) => ({
                        ...provided,
                        width: "max-content"
                    })
                }}
                />

                <a href={sourceLink}>
                    <button>Go to sources</button>
                </a>
            </header>
        </div> 
        );
    }
   };
   
export default Song;
