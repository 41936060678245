import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Main from "./Main";
import 'react-h5-audio-player/lib/styles.css';
import ReactMarkdown from 'react-markdown';
import TimeAgo from 'react-timeago';

const Artist = () => {
    const { artist } = useParams();
    const [artistDescription, setArtistDescription] = useState('');
    const [songs, setSongs] = useState(new Map());

    useEffect(() => {
        const fetchArtistDescription = async () => {
            fetch(`https://looperhub.com/static/cache/${artist}/README.md`)
            //fetch(`/cache/${artist}/README.md`)
            .then((response) => response.text())
            .then(
                (data) => {
                    setArtistDescription(data);
                }
            )    
        }
        fetchArtistDescription();
    }, [artist]);

    useEffect(() => {
        const fetchSongs = async () => {
            fetch(`https://looperhub.com/api/songs/${artist}`)
            //fetch(`/songs/${artist}`)
            .then((response) => response.json())
            .then(async (data) => {
                //var newSongs = new Map();
                let newSongs = [];
                for (let song_id in data) {
                    let song = data[song_id]
                    const response = await fetch(`https://looperhub.com/static/cache/${artist}/${song}/metadata.json`)
                    //const response = await fetch(`http://localhost:3000/cache/${artist}/${song}/metadata.json`);
                    const responseJson = await response.json();
                    newSongs.push({key: song, value: responseJson});
                }
                setSongs(newSongs);
            })            
        }
        fetchSongs();
    }, [artist]);

    if(artistDescription.length === 0) {
        return (
            <Main /> 
        );
    }
    else {
        return (
            <div className="App">
            <header className="App-header">
                <h1>{artist}</h1>
                <ReactMarkdown>{artistDescription}</ReactMarkdown>
                <br/><br/>
                Songs:
                <table width='100%' style={{'textAlign': 'left'}}>
                    <tr><td>Name</td><td>Created</td><td>Last modified</td><td>Total changes</td></tr>
                        {Object.keys(songs).map(song => (
                            <tr>
                                <td><Link to={`/${artist}/${songs[song].key}`}>{songs[song].key}</Link></td>
                                <td><TimeAgo date ={songs[song].value["created_at"]}/></td>
                                <td><TimeAgo date ={songs[song].value["last_modified"]}/></td>
                                <td>{songs[song].value["total_commits"]}</td>
                            </tr>
                        ))}
                </table>
            </header>
            </div> 
        );
    }
   };
   
export default Artist;
