import './App.css';
import { Routes, Route } from 'react-router-dom';
import Main from './Main';
import Artist from './Artist';
import Song from './Song';
//import { useNavigate, useLocation } from 'react-router-dom';
import { load } from '@fingerprintjs/botd';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

function App() {
  //const navigate = useNavigate();
  //const location = useLocation();
  const botdPromise = load();
  const [isBot, setIsBot] = useState(true);

  botdPromise
  .then(botd => botd.detect())
  .then(result => {
    console.log(result);
    //console.log(location);
    setIsBot(result.bot);
    /*
    if(!result.bot && location.pathname === "/") {
      navigate("/DoPrawdy/Surfing", { replace: true});
    }
    */
  });

  return (
    <>
      <Routes>
        <Route path="/*" element={ isBot ? ( <Main /> ) : ( <Navigate replace to={"/DoPrawdy/Surfing"}/> ) }/>
        <Route path="/:artist" element={<Artist />} />
        <Route path="/:user/:title" element={<Song />} />
      </Routes>
    </>
  );
}

export default App;
